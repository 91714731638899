import React, { useState, useRef, useMemo } from 'react';
import ReactWebChat, { createStore } from 'botframework-webchat';
import { DirectLine } from 'botframework-directlinejs';
import Cookies from 'js-cookie'

import logoNoBg from './assets/iconoChat.png';

import imgHeaderIcon from './assets/logoEmpresa.png'
import closeIcon from './assets/close-icon.png';
import imgUser from './assets/user.png';

import './styles/index.js'
import { toast } from 'react-toastify';

const styleOptions = {
    userAvatarInitials: <img src={imgUser} alt="icono usario" />,
    suggestedActionBorderColor: '#005487',
    suggestedActionBorderWidth: 1,
    suggestedActionTextColor: '#005487',
    sendTimeout: 10000,
    bubbleFromUserBackground: '#005487',
    bubbleFromUserTextColor: 'white',
    bubbleBorderRadius: 7,
    bubbleFromUserBorderRadius: 7,
    suggestedActionsCarouselFlipperBoxWidth: 40,
    suggestedActionsCarouselFlipperSize: 40,
    transcriptOverlayButtonBackground: 'rgba(0, 0, 0, .8)',
    transcriptOverlayButtonBackgroundOnFocus: 'rgba(0, 0, 0, .9)',
    transcriptOverlayButtonBackgroundOnHover: 'rgba(0, 0, 0, .9)',
    uploadThumbnailContentType: 'image/jpeg',

}

const verificarSession = () => {
    let conversationId = Cookies.get("conversationId")
    if (conversationId === undefined) {
        return new DirectLine({ secret: '_p8vxnWKRrA.vLe-7Qj7vpXGZIPwE8j0sskH9VFFtU90HZ8ARq4OdOw' })
    } else {
        return new DirectLine({
            secret: '_p8vxnWKRrA.vLe-7Qj7vpXGZIPwE8j0sskH9VFFtU90HZ8ARq4OdOw',
            conversationId: conversationId,
            watermark: "0"
        });
    }

}


const directLine = verificarSession()


directLine.activity$
    .subscribe(
        activity => {
            if (Cookies.get("conversationId") === undefined) {
                Cookies.set("conversationId", activity.conversation.id, { expires: 5 })
            }
        }

    );


const Chatbox = () => {

    const [chatOpen, setChatOpen] = useState(false)
    const listRef = useRef()


    const store = useMemo(() => createStore({}, ({ dispatch }) => next => action => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            directLine
                .postActivity({
                    locale: "es-CR",
                    type: "message",
                    from: {
                        id: "usuarioPrueba"
                    },
                    text: ""
                })
                .subscribe(function (id) {
                    console.log('"trigger requestWelcomeDialog" sent');
                });

        }
        return next(action);
    }), []);


    // Obtiene una referencia al elemento de entrada de archivo y al botón de validación
    var archivoInput = document.querySelector('.webchat__upload-button--file-input');
    console.log(archivoInput)
    if (archivoInput) {
        console.log("92s----------------------------------------- ")
        // Agrega un evento al botón de validación
        archivoInput.addEventListener('change', function () {
            console.log("93s----------------------------------------- ")
            // Obtiene el archivo seleccionado por el usuario
            var archivo = archivoInput.files[0];
            console.log("archivo", archivo)
            if (archivo) {
                // Obtiene la extensión del archivo
                var extension = archivo.name.split('.').pop().toLowerCase();

                // Lista de extensiones permitidas
                var extensionesPermitidas = ['jpg', 'jpeg', 'png'];

                // Verifica si la extensión está en la lista de extensiones permitidas
                if (!extensionesPermitidas.includes(extension)) {
                    document.querySelector('.webchat__upload-button--file-input').value = "";

                }
            }else{
                toast.warning("Formato de archivo inválido", { autoClose: 10000, position: "bottom-right" });
            }
        });
    }
    return (
        <div id="chatbox" className="chatbox">
            <div id="sc-launcher">
                <div className={`sc-launcher ${chatOpen ? "opened" : ""}`} onClick={(() => { setChatOpen(!chatOpen) })} >
                    <img className="sc-open-icon" src={closeIcon} alt="Icono abrir" />
                    <img className="sc-closed-icon" src={logoNoBg} alt="Icono cerrar" />
                </div>
                <div className={`sc-chat-window ${chatOpen ? "opened" : "closed"}`}>
                    <div className="sc-header">
                        <img className="sc-header--img" src={imgHeaderIcon} alt="" />
                        <div className="sc-header--team-name" >Converse con nosotros</div>
                        <div className="sc-header--close-button" onClick={(() => setChatOpen(false))}>
                            <img src={closeIcon} alt="Icono cerrar" />
                        </div>
                    </div>
                    <div className="sc-message-list" ref={listRef}>
                        <ReactWebChat directLine={directLine} store={store} resize="detect" styleOptions={styleOptions} locale={"es-CR"} userID="usuarioPrueba" />
                    </div>
                </div>
            </div>
        </div >)
}
export default Chatbox

